
import React from 'react';
import css from './FaqAfterLogin.module.css';
import Collapsible from 'react-collapsible';

const FaqAfterLogin = () => {
  return (
    <div className={css.faqWrapper}>
      <h3>Frequently Asked Questions (FAQ)</h3>
      <h2>Your Journey to Wellness Starts Here</h2>
      <div className={css.faqContent}>
        <div className={css.faqText}>
          <Collapsible trigger={'What insurances do you accept?'}>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, to
          </Collapsible>
        </div>
        <div className={css.faqText}>
          <Collapsible trigger={'What are your office hours?'}>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, to
          </Collapsible>
        </div>
        <div className={css.faqText}>
          <Collapsible trigger={'How do I schedule an appointment?'}>
            "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, to"
          </Collapsible>
        </div>
        <div className={css.faqText}>
          <Collapsible trigger={'What should I bring to my appointment?'}>
            "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, to"
          </Collapsible>
        </div>

        <div className={css.faqText}>
          <Collapsible trigger={'Do you offer telemedicine or virtual visits?'}>
            "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, to"
          </Collapsible>
        </div>
        <div className={css.faqText}>
          <Collapsible trigger={'What types of conditions do you treat?'}>
            "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, to"
          </Collapsible>
        </div>
      </div>
    </div>
  );
};

export default FaqAfterLogin;
